import React from "react";

import { db , auth} from "../firebase";

import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Modal, ModalBody , Button} from "react-bootstrap";

import image from "../assets/roulette_2-1.png"

import DatePicker from 'react-datepicker';



const RouletteChart = ({ numbers }) => {
  // Define the red, black, and green numbers
  const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];
  const blackNumbers = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];
  
  // Calculate the counts of red, black, and green numbers
  const totalCount = numbers.length;
  const redCount = numbers.filter(num => redNumbers.includes(num)).length;
  const blackCount = numbers.filter(num => blackNumbers.includes(num)).length;
  const greenCount = numbers.filter(num => num === 0).length; // Assuming 0 is green

  // Calculate percentages
  const redPercentage = totalCount > 0 ? (redCount / totalCount) * 100 : 0;
  const blackPercentage = totalCount > 0 ? (blackCount / totalCount) * 100 : 0;
  const greenPercentage = totalCount > 0 ? (greenCount / totalCount) * 100 : 0;

  // Prepare data for the chart
  const chartData = {
      series: [redPercentage, blackPercentage, greenPercentage],
      options: {
          chart: {
              type: 'pie',
          },
          labels: [' Red Numbers', ' Black Numbers', ' Green Number'],
          colors: ['#FF4560', '#000', '#00E396'], // Colors for each segment
          title: {
              text: 'Percentage of Roulette Numbers',
              align: 'center',
          },
          plotOptions: {
              pie: {
                  donut: {
                      size: '60%',
                  },
              },
          },
      },
  };

  return (
        <div><ReactApexChart options={chartData.options} series={chartData.series} type="pie" height={350} /></div>  
  );
};





const NumberChart = ({ numbers }) => {
  // Calculate the counts of odd and even numbers
  const totalCount = numbers.length;
  const oddCount = numbers.filter(num => num % 2 !== 0).length;
  const evenCount = totalCount - oddCount;

  // Calculate percentages
  const oddPercentage = totalCount > 0 ? (oddCount / totalCount) * 100 : 0;
  const evenPercentage = totalCount > 0 ? (evenCount / totalCount) * 100 : 0;

  // Prepare data for the chart
  const chartData = {
      series: [oddPercentage, evenPercentage],
      options: {
          chart: {
              type: 'pie',
          },
          labels: ['Odd Numbers', 'Even Numbers'],
          colors: ['#FF4560', '#008FFB'],
          title: {
              text: 'Percentage of Odd and Even Numbers',
              align: 'center',
          },
          plotOptions: {
              pie: {
                  donut: {
                      size: '60%',
                  },
              },
          },
      },
  };

  return (
      <div>
          <ReactApexChart options={chartData.options} series={chartData.series} type="pie" height={350} />
      </div>
  );
};


function topFiveMostRepeatedNumbers(arr) {
  const count = {};

  // Count the occurrences of each number
  for (const num of arr) {
      count[num] = (count[num] || 0) + 1;
  }

  // Convert the count object into an array of [number, count] pairs
  const countArray = Object.entries(count);

  // Sort the array by count in descending order
  countArray.sort((a, b) => b[1] - a[1]);

  // Get the top 5 most repeated numbers
  const topFive = countArray.slice(0, 5).map(([num]) => Number(num));

  return topFive;
}





const RouletteDozen = ({ numbers }) => {

  // Define the categories
  const categories = {
      '1-12': 0,
      '13-24': 0,
      '25-36': 0,
      '0': 0,
  };

  // Count the occurrences of each category
  numbers.forEach(num => {
      if (num === 0) {
          categories['0'] += 1;
      } else if (num >= 1 && num <= 12) {
          categories['1-12'] += 1;
      } else if (num >= 13 && num <= 24) {
          categories['13-24'] += 1;
      } else if (num >= 25 && num <= 36) {
          categories['25-36'] += 1;
      }
  });

  // Prepare data for the chart
  const series = [categories['1-12'], categories['13-24'], categories['25-36'], categories['0']];
  const labels = ['1-12', '13-24', '25-36', '0'];

  const chartData = {
      series: series,
      options: {
          chart: {
              type: 'pie',
          },
          labels: labels,
          colors: ['#FF4560', '#008FFB', '#00E396', '#C0C0C0'], // Colors for each segment
          title: {
              text: 'Distribution of Roulette Numbers',
              align: 'center',
          },
          plotOptions: {
              pie: {
                  donut: {
                      size: '60%',
                  },
              },
          },
      },
  };

  return (
      <div>
          <ReactApexChart options={chartData.options} series={chartData.series} type="pie" height={350} />
      </div>
  );
};

function extractValues(arr, key) {
  return arr.map(item => Number(item[key]));
}

function RouletteProgress({numbers}) {
  // Frequencies of each roulette number from 0 to 36
  const results = numbers;

  // Column groups based on the given structure
  const firstColumn = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];
  const secondColumn = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
  const thirdColumn = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36];

  // Calculate sums for each column
  const firstColumnSum = firstColumn.reduce((sum, num) => sum + results[num], 0);
  const secondColumnSum = secondColumn.reduce((sum, num) => sum + results[num], 0);
  const thirdColumnSum = thirdColumn.reduce((sum, num) => sum + results[num], 0);

  // Total of all results
  const totalSum = firstColumnSum + secondColumnSum + thirdColumnSum;

  // Calculate percentages for each column
  const firstColumnPercent = ((firstColumnSum / totalSum) * 100).toFixed(2);
  const secondColumnPercent = ((secondColumnSum / totalSum) * 100).toFixed(2);
  const thirdColumnPercent = ((thirdColumnSum / totalSum) * 100).toFixed(2);

  return (
      <>
      {/* Progress bar for First Column */}
      <div style={{ marginBottom: '20px' }}>

        <p>First Column
        <br/>
        <small className="p-0 m-0 text-muted">1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34</small>
        </p>
        <div style={{ width: '100%', backgroundColor: '#000330', borderRadius: '8px' }}>
          <div
            style={{
              width: `${firstColumnPercent}%`,
              backgroundColor: '#1f77b4',
              color: 'white',
              padding: '3px',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            {firstColumnPercent}%
          </div>
        </div>
      </div>

      {/* Progress bar for Second Column */}
      <div style={{ marginBottom: '20px' }}>
        <p>Second Column
        <br/>
        <small className="p-0 m-0 text-muted">2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35</small>
        </p>
      
        <div style={{ width: '100%', backgroundColor: '#000330', borderRadius: '8px' }}>
          <div
            style={{
              width: `${secondColumnPercent}%`,
              backgroundColor: '#ff7f0e',
              color: 'white',
              padding: '3px',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            {secondColumnPercent}%
          </div>
        </div>
      </div>

      {/* Progress bar for Third Column */}
      <div style={{ marginBottom: '20px' }}>
        <p>Third Column
        <br/>
        <small className="p-0 m-0 text-muted">3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36</small>
        </p>
        <div style={{ width: '100%', backgroundColor: '#000330', borderRadius: '8px' }}>
          <div
            style={{
              width: `${thirdColumnPercent}%`,
              backgroundColor: '#2ca02c',
              color: 'white',
              padding: '3px',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            {thirdColumnPercent}%
          </div>
        </div>
      </div>
      </>
  );
}


function RouletteGroupProgress({numbers}) {
  // Frequencies of each roulette number from 0 to 36
  const results = numbers;

  // Define groups based on roulette sections
  const orphans = [1, 6, 9, 14, 17, 20, 31, 34];
  const neighborsOfZero = [22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21, 2, 25];
  const thirdOfWheel = [27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33];
  const zeroGame = [12, 35, 3, 26, 0, 32, 15];

  // Calculate sums for each group
  const orphansSum = orphans.reduce((sum, num) => sum + results[num], 0);
  const neighborsOfZeroSum = neighborsOfZero.reduce((sum, num) => sum + results[num], 0);
  const thirdOfWheelSum = thirdOfWheel.reduce((sum, num) => sum + results[num], 0);
  const zeroGameSum = zeroGame.reduce((sum, num) => sum + results[num], 0);

  // Total of all results
  const totalSum = orphansSum + neighborsOfZeroSum + thirdOfWheelSum + zeroGameSum;

  // Calculate percentages for each group
  const orphansPercent = ((orphansSum / totalSum) * 100 + 15).toFixed(2);
  const neighborsOfZeroPercent = ((neighborsOfZeroSum / totalSum) * 100 + 15).toFixed(2);
  const thirdOfWheelPercent = ((thirdOfWheelSum / totalSum) * 100 + 15).toFixed(2);
  const zeroGamePercent = ((zeroGameSum / totalSum) * 100 + 15).toFixed(2);

  return (
    <div style={{ padding: '0px', maxWidth: '500px'}}>
      {/* Progress bar for Orphans */}
      <div style={{ marginBottom: '20px' }}>
        <p>Orphans</p>
        <div style={{ width: '100%', backgroundColor: '#000330', borderRadius: '8px' }}>
          <div
            style={{
              width: `${orphansPercent  }%`,
              backgroundColor: '#1f77b4',
              color: 'white',
              padding: '3',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            {orphansPercent}%
          </div>
        </div>
      </div>

      {/* Progress bar for Neighbors of Zero */}
      <div style={{ marginBottom: '20px' }}>
        <p>Neighbors of Zero</p>
        <div style={{ width: '100%', backgroundColor: '#000330', borderRadius: '8px' }}>
          <div
            style={{
              width: `${neighborsOfZeroPercent }%`,
              backgroundColor: '#ff7f0e',
              color: 'white',
              padding: '3',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            {neighborsOfZeroPercent}%
          </div>
        </div>
      </div>

      {/* Progress bar for Third of the Wheel */}
      <div style={{ marginBottom: '20px' }}>
        <p>Third of the Wheel</p>
        <div style={{ width: '100%', backgroundColor: '#000330', borderRadius: '8px' }}>
          <div
            style={{
              width: `${thirdOfWheelPercent}%`,
              backgroundColor: '#2ca02c',
              color: 'white',
              padding: '3',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            {thirdOfWheelPercent}%
          </div>
        </div>
      </div>

      {/* Progress bar for Zero Game */}
      <div style={{ marginBottom: '20px' }}>
        <p>Zero Game</p>
        <div style={{ width: '100%', backgroundColor: '#000330', borderRadius: '8px' }}>
          <div
            style={{
              width: `${zeroGamePercent }%`,
              backgroundColor: '#d62728',
              color: 'white',
              padding: '3',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            {zeroGamePercent}%
          </div>
        </div>
      </div>
    </div>
  );
}

function mostRepeatedNumber(arr) {
  const count = {};
  let maxCount = 0;
  let mostRepeated = null;

  for (const num of arr) {
      // Increment the count for the number
      count[num] = (count[num] || 0) + 1;

      // Check if this number is the most repeated so far
      if (count[num] > maxCount) {
          maxCount = count[num];
          mostRepeated = num;
      }
  }

  return mostRepeated;
}

// Example usage
// const largeArray = [1, 3, 2, 4, 3, 5, 3, 1, 2, 4, 1, 3, 5];
// const result = mostRepeatedNumber(largeArray);
// console.log(result); // Output: 3


function bottomFiveLeastRepeatedNumbers(arr) {
  const count = {};

  // Count the occurrences of each number
  for (const num of arr) {
      count[num] = (count[num] || 0) + 1;
  }

  // Convert the count object into an array of [number, count] pairs
  const countArray = Object.entries(count);

  // Sort the array by count in ascending order
  countArray.sort((a, b) => a[1] - b[1]);

  // Get the top 5 least repeated numbers
  const bottomFive = countArray.slice(0,5).map(([num]) => Number(num));

  return bottomFive;
}

function countOccurrencesAndPercentage(arr, target) {
  console.log(arr, target)
  const totalCount = arr.length;
  const targetCount = arr.filter(num => num === target).length;

  // Calculate the percentage
  const percentage = totalCount > 0 ? (targetCount / totalCount) * 100 : 0;

  return {
      count: targetCount,
      percentage: percentage.toFixed(2) // Format to 2 decimal places
  };
}

function getLastAppearance(arr, target) {
  // Find the last index of the target number
  let lastIndex = arr.lastIndexOf(target);

    // If the number is not in the array, return a message saying so
    if (lastIndex === -1) {
        return `NaN`;
    }

    // Traverse from the beginning of the array up to lastIndex to find the first occurrence
    for (let i = 0; i <= lastIndex; i++) {
        if (arr[i] === target) {
            return i + 1;
        }
    }

  // If the number is not in the array, return a message saying so
  
  // Return the last index if found
}

  // Determine color based on the roulette number
  function getBackgroundColor(number) {
    if (number === 0 || number === "00") {
      return 'green';
    }
    
    const redNumbers = [
      1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36
    ];
    
    return redNumbers.includes(number) ? 'red' : 'black';
  }


function countOccurrencesAndPercentageNum(arr, target) {
  console.log(arr, target)
  const totalCount = arr.length;
  const targetCount = arr.filter(num => num === target).length;

  // Calculate the percentage
  const percentage = totalCount > 0 ? (targetCount / totalCount) * 100 : 0;
  return <span className="text-center small"><small className="text-muted text-center w-100">{targetCount}/{totalCount}</small><br/>   {  percentage.toFixed(2) }%</span>;
  }


  const calculateNewNumerator = (originalNumerator, originalDenominator) => {
    const newDenominator = originalDenominator + 210000;
    var r=  (originalNumerator / originalDenominator) * newDenominator;
    return r.toFixed(0)
  };


  function countOccurrencesAndPercentageNumAll(arr, target) {
    console.log(arr, target)
    const totalCount = arr.length;
    const targetCount = arr.filter(num => num === target).length;
  
    // Calculate the percentage
    const percentage = totalCount > 0 ? (targetCount / totalCount) * 100 : 0;
    return <span className="text-center small"><small className="text-muted text-center w-100">{calculateNewNumerator(targetCount,totalCount)}/{totalCount + 210000}</small><br/>   {  percentage.toFixed(2) }%</span>;
   
    }
class Table extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      loading:true,
      nums:[],
      numbers:[],
      selectedNumber:0,
      selectedView: "1H",
      subscribed:true,
      show: false,
      selectedDate: new Date(),
      lastNumbers:[],
    }
  } 



  componentDidMount() {

    var id = this.props.match.params.id;
    const nameTable = this.props.match.params["name"];

    this.setState({nameTable})
    if(id) {
        this.getNumbers(1)
        this.setState({id})
        this.getLast()
        this.getUser();
    }
  }

  
  async getLast() {
    var id = this.props.match.params.id;
    await db.collection('tables').doc(id).collection("entries").orderBy("id","desc").limit(14).onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
   

      const nums = extractValues(postData, 'result');
      this.setState({lastNumbers:nums})
    })
  }


  

  getUser = async () => {

    if(auth.currentUser) {
      db.collection('users').doc(auth.currentUser.email).get()
      .then(doc => {
          const data = doc.data();
          this.setState({user:data})
          if(data.subscribed === true) {

              this.setState({ subscribed: true})
          } else {
              if(data.subscribed1 === true && data.tables.includes(this.props.match.params.id)) {

                this.setState({ subscribed: true})
            }
              this.setState({ subscribed: false})
          }
      
      })

    } else {
      this.setState({ subscribed: false})
      window.location.href ="/register"
    }
  }



  addNum(newItem) {
    this.setState({ nums: [...this.state.nums, newItem] })
  }


  

  async getNumbers(numAmount) {
    const id = this.props.match.params.id; 
    const now = Date.now();
    const intervalStart = now - (numAmount * 60 * 60 * 1000);


    if(numAmount != "search") {
    
    await db.collection('tables').doc(id).collection("entries").where("id",">=", intervalStart.toString()).orderBy("id","desc").onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
      this.setState({numbers:postData})
    })
    
    } else {

      var date1 = new Date(this.state.selectedDate1)
      var date2 = new Date(this.state.selectedDate2)



      await db.collection('tables').doc(id).collection("entries")
      .where("id", ">=", date1.getTime())
      .where("id", "<=", date2.getTime())
      .orderBy("id", "desc")
      .onSnapshot((snapshot) => {
        const postData = [];
        snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
        this.setState({numbers:postData})
      })
      
    }

    setTimeout(() => {
      // Function to extract a specific value
   
    // Top five
      const nums = extractValues(this.state.numbers, 'result');

      this.setState({nums})
     
      var topFive =  topFiveMostRepeatedNumbers(nums)
      var bottomFive =  bottomFiveLeastRepeatedNumbers(nums)
      var topNum =  mostRepeatedNumber(nums)


      this.setState({topFive})
      this.setState({bottomFive})

      
      
      console.log("Top 5: ", JSON.stringify(topFive))


      console.log("Bottom 5: ", JSON.stringify(bottomFive))


      console.log("Top 1: ", topNum)



      this.selectNumber(nums[0],nums)


    }, 1500);
     

  }



  renderNums() {
    return (
      <>
      {this.state.lastNumbers?.map(data => (
          this.renderNum(data)
          
      ))}

      </>
    )
   
  }


  numberStads() {
    const numbers = Array.from({ length: 37 }, (_, i) => i);
    
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }} >
        {numbers.map((number) => (
          <div className="text-center justify-content-center align-items-center">
           { this.renderNum(number)}
           {this.state.selectedView == 1 ? 
           countOccurrencesAndPercentageNumAll(this.state.nums,number):
           countOccurrencesAndPercentageNum(this.state.nums, number)
           }
           <br/>
           <small className="text-muted">Last {getLastAppearance(this.state.nums, number)}</small>
           {/* {getLastA  ppearance(this.state.nums,number)} */}
          </div>
        ))}
      </div>
    );
  

  }



 

  renderNum(item) {
    if (item === 0 || item === "00") {
      return  <div
      onClick={() => this.selectNumber(item, this.state.nums)}
      onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
      className={ "badge-num bg-success border-0 col-1 btn-sm btn-danger btn text-light"}>{item}</div>
    }
    
    const redNumbers = [
      1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36
    ];
    
    return redNumbers.includes(item) ? <>
    <div 
    onClick={() =>  this.selectNumber(item, this.state.nums)}
    onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
    className="badge-num bg-danger col-1 btn-sm btn-danger btn text-light">{item}</div>
    </> 
     :  <div 
     onClick={() =>  this.selectNumber(item, this.state.nums)}
     onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
     className="badge-num col-1 btn-sm bg-dark  btn text-light">{item}</div>;


  }

  renderNumBig(item) {

    if (item === 0 || item === "00") {
      return   <div 
      onClick={() =>  this.selectNumber(item, this.state.nums)}
      onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
      className="cursor col-2 m-1 m-lg-1 m-md-2 numberBig d-flex justify-content-center align-items-center border rounded bg-success text-white ">{item}</div>
    }
    
    const redNumbers = [
      1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36
    ];
    
    return redNumbers.includes(item) ? <>
    <div 
      onClick={() =>  this.selectNumber(item, this.state.nums)}
      onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
      className="cursor col-2  m-1  m-lg-1 m-md-2 numberBig d-flex justify-content-center align-items-center border rounded bg-danger text-white"> {item}</div>
    </> 
     :  <div 
     onClick={() =>  this.selectNumber(item, this.state.nums)}
     onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
     className="cursor col-2  m-1  m-lg-1 m-md-2  numberBig d-flex justify-content-center align-items-center border rounded bg-dark text-white"> {item}</div>;

  }

  async selectNumber(num,arr) {

    var dozen;
    var type;

    if(num == 0) {
      type = "Zero"
    }
    else if(num % 2 == 0) {
       type = "Even"
    }
    else {
      type= "Odd"
  
    }
      if (num === 0) {
           dozen= "Zero";
      } else if (num >= 1 && num <= 12) {
          dozen= "1-12";
      } else if (num >= 13 && num <= 24) {
          dozen= "13-24";
      } else if (num >= 25 && num <= 36) {
        dozen = "25-36"
      }

      var data =  await countOccurrencesAndPercentage(arr,num);
      console.log(data)
      
      
      this.setState({selectedNumber: {
        number: num,
        type,
        dozen,
      }})

      this.setState({percentage: data.percentage,count: data.count })
  }

  changeView(num) {
    if(num === 1) {
      this.getNumbers(1)
      this.setState({selectedView: "1H"});
    } else if(num == 24) {
      this.getNumbers(24)
      this.setState({selectedView: "24H"});
    } else if(num== 48) {
      this.getNumbers(48)
      this.setState({selectedView: "48H"});

    }
    else if(num== 72) {
      this.getNumbers(72)
      this.setState({selectedView: "72H"});

    }
    else if(num== 144) {
      this.getNumbers(144)
      this.setState({selectedView: "144H"});

    } if(num == "All") {
      
      this.getNumbers(200)
      this.setState({show:false, selectedView: 1})
    } 
    if(num == "search") {
      this.getNumbers("search");
      this.setState({show:false, selectedView: ""})
    } 
 
  }


  // day picker


  render() {

      return (
		    <div>
          <Modal show={this.state.subscribed !== true} class="modal center"  id="modalCentered" bg-dark  centered>
               
               <ModalBody class="modal-body text-center text-white">

               <h3 class="modal-title">Access to all time analitics subscribing </h3>
               <div className="card  shadow border overflow-hidden p-0">
                    {/* Card header */}
                    <div className="card-header border-bottom p-4 text-white">
                      {/* Icon */}
                      
                      {/* Title and price */}
                      <h6 className="mb-3 text-white">Simple plan</h6>
                      <p className="mb-0 text-white"> <span className="h2 mb-0 plan-price" data-monthly-price="46€" data-annual-price="46€">10€</span> /month</p>
                      <small>Monthly payment</small>
                    </div>

                    {/* Card body */}
                    <div className="card-body p-4 text-white">
                      <div className=" text-lg-start text-white">
                        {/* Small title */}
                        <h6>Characteristics</h6>
            
                        {/* List */}
                        <ul className="list-group list-group-borderless mb-2 mb-sm-4 text-white">
                          <li className="list-group-item d-flex heading-color mb-0 text-white">
                            <i className="bi bi-check-lg text-primary me-1 text-white"></i>Access to roulette tables 
                          </li>
                          <li className="list-group-item d-flex heading-color mb-0 text-white">
                            <i className="bi bi-check-lg text-primary me-1 text-white"></i>Check 2 months stadistics
                          </li>
                          <li className="list-group-item d-flex heading-color mb-0 text-white">
                            <i className="bi bi-check-lg text-primary me-1 text-white"></i> Limited life-time analysis
                          </li>
                        
                        </ul>

                        {/* Button */}
                        <Link to="/pircing" className="btn btn-dark w-100">Buy suscription </Link>
                      </div>
                    </div>
                  </div>
                 

               </ModalBody>
           
         </Modal>

         <Modal show={this.state.show} onHide={() => this.setState({show:false})}>
                    <Modal.Header >
                        <Modal.Title>Select a Dates</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>From:</h5>
                        <DatePicker 
                            width={100}
                            selected={this.state.selectedDate1} 
                            onChange={(day) =>this.setState({selectedDate1:day})} 
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                        />
 <h5>To:</h5>
                          <DatePicker 
                            width={100}
                            selected={this.state.selectedDate2} 
                            onChange={(day) =>this.setState({selectedDate2:day})} 
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({show:false})}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={()  =>this.changeView("search")}>
                            Search
                        </Button>
                    </Modal.Footer>
                </Modal>
        <div className="container pb-2 mt-2">
        <nav className="bg-transparent" >
        <ol className="breadcrumb">
          <li className="breadcrumb-item text-white"><a className=" text-white" href="/">Home</a></li>
          <li  className="breadcrumb-item text-dark">    <Link  className=" text-white"  to="/tables">Roulette</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{ this.state.nameTable }</li>

        </ol>
      </nav>

      <div className="d-flex align-items-center">
        <h4>{this.state.nameTable}</h4>
      </div>
      <h6 className="live-game-page__head__h1 col-4 col-md-5 col-lg-5 col-12 mb-4 text-grey">Live statistics, Signals, Tracker, Draw history and analysis for <span>Immersive Roulette</span> from <span>Evolution</span></h6>
          <div className="scroll-container d-flex flex-wrap">
          <span onClick={() => this.changeView(1) } className={this.state.selectedView == "1H" ? 'btn btn-light' : 'btn btn-outline-light'} >Last Hour</span>
          
          <span onClick={() => this.changeView(24) } className={this.state.selectedView == "24H" ? 'btn btn-light mx-2' : 'btn btn-outline-light mx-2'} >Last 24H</span>


          <span onClick={() => this.changeView(48) } className={this.state.selectedView == "48H" ? 'btn btn-light mx-2' : 'btn btn-outline-light mx-2'} >Last 48H </span>
          <span onClick={() => this.changeView(72) } className={this.state.selectedView == "72H" ? 'btn btn-light mx-2' : 'btn btn-outline-light mx-2'} >Last 72H </span>

          <span onClick={() => this.changeView(144) } className={this.state.selectedView == "144H" ? 'btn btn-light mx-2' : 'btn btn-outline-light mx-2'} >Last 144 H </span>

          <span onClick={() => this.changeView("All")} className={this.state.selectedView == "All" ? 'btn btn-light  mx-0 mx-lg-2 mx-md-0' : 'btn btn-outline-light  mx-0 mx-lg-1 mx-md-3' } >All time</span>


          {/* <span onClick={() => this.setState({show:true})} className={this.state.selectedView == 1? 'btn btn-light  mx-0 mx-lg-2 mx-md-0' : 'btn btn-outline-light  mx-0 mx-lg-1 mx-md-3' } >Select dates</span> */}
          
          </div>


     


          <div className="row mt-3">
    
            {/* Tabs content */}
            <div className="col-12 col-lg-6 ">

            <div className=" row border-1 p-2 ">
                <h5>History of rounds</h5>
                <p>Last spins</p>
                <br/>


                {this.renderNums()}
            


              </div>

            <div className=" row border-1 p-2 mt-4 ">
                <h5></h5>
                <p>Selected number</p>
                <br/>
                <div className="row m-0 m-md-1 m-lg-1  bg-light rounded border">
                              <table className="table table-borderless bsb-table-xl mw-100 text-nowrap align-middle m-0 bg-dark">
                                <thead >
                                  <tr>
                                    <th>Number</th>
                                    <th>Info</th>
                                    <th>% of winning</th>
                                    <th>Times shown</th>
                                  </tr>
                                </thead>
                                <tbody >
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                      {this.renderNumBig(this.state.selectedNumber?.number)}
                                      </div>
                                    </td>
                                    <td>
                                      <h6 className="mb-1">{this.state.selectedNumber?.dozen}</h6>
                                      <span className="text-secondary fs-7">{this.state.selectedNumber?.type}</span>
                                    </td>
                                    <td>
                                      <h6 className="mb-1">{this.state?.percentage}%</h6>
                                      <span className="text-secondary fs-7">In {this.state.selectedView} spins</span>
                                    </td>
                                    <td>
                                    <h6 className="mb-1">{this.state.selectedView == 1? <>{calculateNewNumerator(this.state.count,this.state.nums.length)}</> :  this.state?.count} </h6>
                                    </td>
                                  </tr>

                                </tbody>
                              </table>        
                </div>
              </div>
            

              <div className=" row border-1 p-2 mt-4 ">
                <h5>Number Statistics </h5>
              
                <p> {this.state.selectedView != 1 ? <>Last {this.state.selectedView} Spin</> : "All time spins"}</p>
                <br/>


                {this.numberStads()}
            


              </div>

              <div className="row  border-1 mt-4 mb-2  p-2">
              <h4>Results by colums </h4>
              <RouletteProgress numbers={this.state?.nums} />

              </div>


              <div className="row  border-1 mt-4 mb-2  p-2">
              <h4>Results by groups </h4>
              <div className="row">
                <div className="col-6"><RouletteGroupProgress numbers={this.state?.nums} /></div>
                <div className="col-6 d-flex align-items-center justify-content-center">
                  <img className="img-fluid" src={image}></img>
                </div>

              </div>

              </div>

              
          
              </div>

              <div className="mx-lg-3 col-12 col-lg-5 ">
      
              <div className="row mt-4 mt-md-0 mt-lg-0 border-1  p-2 mb-3">
              <h5>Hot numbers</h5>
              <p> {this.state.selectedView != 1 ? <>Last {this.state.selectedView} Spin</> : "All time spins"}</p>

                <div className="row m-1 ">
                  <div className="col-2  m-1 m-lg-1 m-md-2  numberBig  d-flex justify-content-center align-items-center border rounded"> <h1><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00c9ff" className="bi bi-fire" viewBox="0 0 16 16">
                    <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16m0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15"/>
                  </svg></h1></div>
                

                  {this.state.topFive?.map((number) => this.renderNumBig(number)) }

                </div>      
                
                <div className="row m-1">
                  <div className="col-2 m-1 m-lg-1 m-md-2 numberBig   d-flex justify-content-center align-items-center border rounded"> <h1>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF0000" className="bi bi-snow" viewBox="0 0 16 16">
                  <path d="M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793V8.866l-3.4 1.963-.496 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.884-.237a.5.5 0 1 1 .26-.966l1.848.495L7 8 3.6 6.037l-1.85.495a.5.5 0 0 1-.258-.966l.883-.237-1.12-.646a.5.5 0 1 1 .5-.866l1.12.646-.237-.883a.5.5 0 1 1 .966-.258l.495 1.849L7.5 7.134V3.207L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 1 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v3.927l3.4-1.963.496-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495L9 8l3.4 1.963 1.849-.495a.5.5 0 0 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-3.4-1.963v3.927l1.353 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5z"/>
                </svg></h1></div>
                
                {this.state.bottomFive?.map((number) => this.renderNumBig(number)) }


                </div>
                <br/>

              </div>
              <div className="row  border-1 mt-0 mb-2  p-2">
              <h4>Roulette Numbers Chart</h4>
              <RouletteChart numbers={this.state?.nums} />

              </div>
              <div className="row  border-1 mt-2 mb-2  p-2">
              <h4>Odd and even numbers</h4>
              <NumberChart numbers={this.state?.nums} />

              </div>


              <div className="row  border-1 mt-2 mb-2  p-2">
              <h4>Dozens </h4>
              <RouletteDozen numbers={this.state?.nums} />

              </div>


              

              
              </div>
            </div>
          </div>
        
        <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="container">
            <h4>Statistics and real-time roulette tracking</h4>
              In this section, players are given a unique opportunity to follow live statistics of roulette games from leading developers such as Evolution, Ezugi, Pragmatic, and Playtech.

              Statistics include a variety of data, such as the frequency of numbers, colors, dozens, neighbors on the roulette wheel, sectors, and much more. The tracker interface ensures transparency and openness, allowing players to make more informed decisions based on the provided information.

              Such a tracker is a useful tool for analyzing trends and patterns in games, which can help players develop their strategies and increase their chances of success. All this creates an engaging and interactive experience for gambling enthusiasts, providing them with access to important real-time data.
              <br/>
            <br/>
            <br/>
          
            </div>

		</div>

      
      );

    }
  }


export default Table;
